// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2LaXf {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  font-size: 1.8em;\n  color: var(--gray2);\n  margin-right: 4em;\n}\n\n.index-module__switch___18xck {\n  cursor: pointer;\n}\n\n.index-module__active___2z6t- {\n  color: var(--primary-text);\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,aAAa;EACb,sBAAmB;MAAnB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","file":"index.module.css","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  font-size: 1.8em;\n  color: var(--gray2);\n  margin-right: 4em;\n}\n\n.switch {\n  cursor: pointer;\n}\n\n.active {\n  color: var(--primary-text);\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2LaXf",
	"switch": "index-module__switch___18xck",
	"active": "index-module__active___2z6t-"
};
module.exports = exports;
