// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___RTuu4 {\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n\n.index-module__content___eUuY6 {\n  width: 70em;\n  text-align: center;\n}\n\n.index-module__buttons___2wUOB {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n  margin-top: 6em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAY;MAAZ,YAAY;EACZ,oBAAa;EAAb,aAAa;EACb,sBAAmB;MAAnB,mBAAmB;EACnB,qBAAuB;MAAvB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,oBAAa;EAAb,aAAa;EACb,sBAAmB;MAAnB,mBAAmB;EACnB,yBAA6B;MAA7B,6BAA6B;EAC7B,eAAe;AACjB","file":"index.module.css","sourcesContent":[".container {\n  flex-grow: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  width: 70em;\n  text-align: center;\n}\n\n.buttons {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  margin-top: 6em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___RTuu4",
	"content": "index-module__content___eUuY6",
	"buttons": "index-module__buttons___2wUOB"
};
module.exports = exports;
