// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___17G17 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  font-size: 1.8em;\n  color: var(--primary-text);\n  margin-right: 4.111em;\n  cursor: pointer;\n}\n\n\n.index-module__inactive___uOj9b {\n  color: var(--gray2);\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,aAAa;EACb,sBAAmB;MAAnB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;EAC1B,qBAAqB;EACrB,eAAe;AACjB;;;AAGA;EACE,mBAAmB;AACrB","file":"index.module.css","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  font-size: 1.8em;\n  color: var(--primary-text);\n  margin-right: 4.111em;\n  cursor: pointer;\n}\n\n\n.inactive {\n  color: var(--gray2);\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___17G17",
	"inactive": "index-module__inactive___uOj9b"
};
module.exports = exports;
