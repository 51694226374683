// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___16SjJ {\n  color: var(--gray1);\n  font-weight: 400;\n  font-size: 2.4em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".container {\n  color: var(--gray1);\n  font-weight: 400;\n  font-size: 2.4em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___16SjJ"
};
module.exports = exports;
