// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3-kRz {\n  background-color: var(--secondary-background);\n  border-radius: 0.6em;\n  border: 1px solid var(--border-color);\n  padding: 2.5em;\n  box-sizing: border-box;\n  width: 100%;\n  position: relative;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,oBAAoB;EACpB,qCAAqC;EACrC,cAAc;EACd,sBAAsB;EACtB,WAAW;EACX,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".container {\n  background-color: var(--secondary-background);\n  border-radius: 0.6em;\n  border: 1px solid var(--border-color);\n  padding: 2.5em;\n  box-sizing: border-box;\n  width: 100%;\n  position: relative;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3-kRz"
};
module.exports = exports;
