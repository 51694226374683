// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__one___3KXfN,.index-module__two___3A5Kc,.index-module__four___1LmZr,.index-module__five___3RIG9 {\n  color: var(--primary-text);\n}\n\n.index-module__one___3KXfN {\n  font-size: 6em;\n  font-weight: 600;\n}\n\n.index-module__two___3A5Kc {\n  line-height: 1.30677em;\n  font-size: 4.8em;\n  font-weight: 600;\n}\n\n.index-module__three___1pgae {\n  font-size: 4.8em;\n  color: var(--primary-color);\n  line-height: 1.30677em;\n}\n\n.index-module__four___1LmZr {\n  font-size: 4.2em;\n  font-weight: 300;\n  line-height: 1.4712em;\n}\n\n.index-module__five___3RIG9 {\n  font-size: 4em;\n  font-weight: 300;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".one,.two,.four,.five {\n  color: var(--primary-text);\n}\n\n.one {\n  font-size: 6em;\n  font-weight: 600;\n}\n\n.two {\n  line-height: 1.30677em;\n  font-size: 4.8em;\n  font-weight: 600;\n}\n\n.three {\n  font-size: 4.8em;\n  color: var(--primary-color);\n  line-height: 1.30677em;\n}\n\n.four {\n  font-size: 4.2em;\n  font-weight: 300;\n  line-height: 1.4712em;\n}\n\n.five {\n  font-size: 4em;\n  font-weight: 300;\n}\n"]}]);
// Exports
exports.locals = {
	"one": "index-module__one___3KXfN",
	"two": "index-module__two___3A5Kc",
	"four": "index-module__four___1LmZr",
	"five": "index-module__five___3RIG9",
	"three": "index-module__three___1pgae"
};
module.exports = exports;
