// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__banner___2fEt3 {\n  height: 29.6em;\n  padding: 0 var(--padding);\n  background-image: linear-gradient(to right, var(--banner-start) , var(--banner-end));\n  position: relative;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-direction: row-reverse;\n      flex-direction: row-reverse;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,oFAAoF;EACpF,kBAAkB;EAClB,oBAAa;EAAb,aAAa;EACb,sBAA8B;MAA9B,8BAA8B;EAC9B,+BAA2B;MAA3B,2BAA2B;AAC7B","file":"index.module.css","sourcesContent":[".banner {\n  height: 29.6em;\n  padding: 0 var(--padding);\n  background-image: linear-gradient(to right, var(--banner-start) , var(--banner-end));\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row-reverse;\n}\n"]}]);
// Exports
exports.locals = {
	"banner": "index-module__banner___2fEt3"
};
module.exports = exports;
